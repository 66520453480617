var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "app-header navbar"
  }, [_c('button', {
    staticClass: "navbar-toggler mobile-sidebar-toggler d-lg-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.mobileSidebarToggle
    }
  }, [_c('i', {
    staticClass: "fa fa-bars",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }), _c('button', {
    staticClass: "navbar-toggler sidebar-toggler d-md-down-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.sidebarToggle
    }
  }, [_c('i', {
    staticClass: "fa fa-bars",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('b-navbar-nav', {
    staticClass: "ml-auto"
  }, [_c('b-dropdown', {
    attrs: {
      "text": _vm.name,
      "variant": "link",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_vm._v(" " + _vm._s(_vm.auth ? _vm.auth.username : '-') + " "), _c('span', {
    staticClass: "fa fa-user-circle"
  })]), _c('b-dropdown-item'), _c('b-dropdown-header', {
    staticClass: "text-center",
    attrs: {
      "tag": "div"
    }
  }, [_c('strong', [_vm._v("Settings")])]), _c('b-dropdown-item', {
    on: {
      "click": _vm.showDetail
    }
  }, [_c('i', {
    staticClass: "fa fa-user"
  }), _vm._v(" Profile ")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.actionLogout();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-lock"
  }), _vm._v(" Logout")])], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }