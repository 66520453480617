<template>
  <footer class="app-footer text-center">
    <span>
      <a href="https://edutore.com"> CMS Ebooks </a>
      &copy; {{ year }} By Gramedia
    </span>

    <svg class="heart" viewBox="0 0 32 29.6">
      <path
        d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
        c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
      />
    </svg>
  </footer>
</template>
<script>
import moment from "moment";
export default {
  name: "footers",
  data() {
    return {
      year: ""
    };
  },
  mounted() {
    this._actionYear();
  },
  methods: {
    _actionYear() {
      return (this.year = moment().format("YYYY"));
    }
  }
};
</script>
<style>
.heart {
  fill: red;
  position: relative;
  height: 20px;
  top: 5px;
  margin-bottom: 8px;
  width: 50px;
  animation: pulse 1.5s ease infinite;
}
.app-footer {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
