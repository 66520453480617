var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "app-footer text-center"
  }, [_c('span', [_c('a', {
    attrs: {
      "href": "https://edutore.com"
    }
  }, [_vm._v(" CMS Ebooks ")]), _vm._v(" © " + _vm._s(_vm.year) + " By Gramedia ")]), _c('svg', {
    staticClass: "heart",
    attrs: {
      "viewBox": "0 0 32 29.6"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2\n      c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }