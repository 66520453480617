<template>
  <header class="app-header navbar">
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <b-link class="navbar-brand" to="/"> </b-link>

    <button
      class="navbar-toggler sidebar-toggler d-md-down-none"
      type="button"
      @click="sidebarToggle"
    >
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <b-navbar-nav class="ml-auto">
      <!-- <label for="label-name" class="label mr-1">
        {{ auth ? auth.username : '-'}}
      </label> -->
      <b-dropdown
        :text="name"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      > 
        <template slot="button-content"
          >
        {{ auth ? auth.username : '-'}}
          <span class="fa fa-user-circle"></span
        ></template>
        <b-dropdown-item> </b-dropdown-item>
        <b-dropdown-header tag="div" class="text-center"
          ><strong>Settings</strong></b-dropdown-header
        >
        <b-dropdown-item @click="showDetail">
          <i class="fa fa-user"></i> Profile
        </b-dropdown-item>
        <b-dropdown-item @click="actionLogout()"
          ><i class="fa fa-lock"></i> Logout</b-dropdown-item
        >
      </b-dropdown>
    </b-navbar-nav>
  </header>
</template>
<script>
import{ mapState,mapMutations} from "vuex"
export default {
  name: "headers",
  data() {
    return {
      name: ""
    };
  },
  computed: {
    ...mapState({
      auth : (state) => state.auth.auth,
      isLogin:(state)=>state.auth.isAuthenticated,
    }),
  },
  methods: {
    ...mapMutations('auth',['setAuth',"setLogin"]),
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-hidden");
    },
    sidebarMinimize(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-minimized");
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-mobile-show");
    },
    asideToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("aside-menu-hidden");
    },
    actionLogout() {
       this.$swal
        .fire({
          title: "Are you sure?",
          text: "Logout Now",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Logout!",
        })
        .then((result) => {
          if (result.isConfirmed) {
             localStorage.clear();
            this.setLogin(false);
            this.setAuth({});
            window.location.reload();
          }
        });
    },
    showDetail() {
      this.$router.push({
        path: "/settings/profile"
      });
    }
  },
  created: function() {
    this.name = localStorage.getItem("session_name");
    let auth = localStorage.getItem("session_auth");
    if (this.name && auth) {
      this.setAuth(JSON.parse(auth));
    }
  }
};
</script>
<style>
.navbar-nav .nav-link,
.navbar-nav .navbar .dropdown-toggle,
.navbar .navbar-nav .dropdown-toggle {
  color: black;
}
</style>
