<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <Sidebar :navItems="nav" />
      <main class="main">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <AppFooter />
  </div>
</template>
<script>
import Http from "../plugins/http"
import AppHeader from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import AppFooter from "../components/Footer";
export default {
  name: "full",
  components: {
    AppHeader,
    Sidebar,
    AppFooter
  },
  data() {
    return {
      nav: [],
    };
  },
  mounted() {
    this.actionGetMenu()
  },
  computed: {
    
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched;
    }
  },
  methods: {
    actionGetMenu(){
      
      Http.get('/menu').then(response => {
        this.nav = response.data.data.menu;
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
};
</script>
<style>
@import url("../assets/css/bootstrap.min.css");
@import url("../assets/css/style.css");
@import url("../assets/font-awesome/css/font-awesome.min.css");
</style>
<style scoped>
.app {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
