var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "nav-item nav-dropdown",
    attrs: {
      "tag": "li",
      "to": _vm.url,
      "disabled": ""
    }
  }, [_c('div', {
    staticClass: "nav-link nav-dropdown-toggle",
    on: {
      "click": _vm.handleClick
    }
  }, [_c('i', {
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " ")]), _c('ul', {
    staticClass: "nav-dropdown-items"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }